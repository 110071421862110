/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_user_files_s3_bucket": "data-670634105186133156-prod",
    "aws_user_files_s3_bucket_region": "ap-northeast-1",
    "aws_content_delivery_bucket": "5gcloudweb-20230330144133-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://d3iu8dgjhyp1y9.cloudfront.net",
    "aws_appsync_graphqlEndpoint": "https://jmmr5tuekjfk7ej4stsbv6qbpy.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-unid7ha4sbhavoqp4p6mel5xfm",
    "aws_cloud_logic_custom": [
        {
            "name": "CreateTimeSeriesApi",
            "endpoint": "https://zib6usv95a.execute-api.ap-northeast-1.amazonaws.com/prod",
            "region": "ap-northeast-1"
        }
    ]
};


export default awsmobile;
